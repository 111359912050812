import React from 'react'
import Layout from '../../components/layout'
import { MatchingCreator } from '../../components/ChallengeCreators'

const MatchingCreatorPage = ({ challengeId }) => (
  <Layout>
    <MatchingCreator challengeId={challengeId} />
  </Layout>
)

export default MatchingCreatorPage
